<template>
  <v-row
    row
    wrap
    class="layout-index"
  >
    <v-col v-for="(page, index) in addEnabledToPages"
            v-bind:key="index"
      class="index-div col-12 col-sm-6 col-md-4"
      @mouseenter="$set(isHover, page.name, true)"
      @mouseleave="$set(isHover, page.name, false)"
    >
      <v-tooltip top :disabled="page.isEnabled && haveUserTheRoles(page.roles)">
        <template v-slot:activator="{ on }">
          <div style="height: 100%" v-on="on">
            <v-btn class="router-link-index" :to="page.path" :disabled="!page.isEnabled || !haveUserTheRoles(page.roles)" :class="generateClass(page)">
              <div class="index-link">
                <v-row class="justify-center">
                  <v-col class="shrink"><v-icon>{{page.icon}}</v-icon><span class="index-span-text">{{page.text}}</span></v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <p class="index-text-content">
                {{page.content}}
              </p>
            </v-btn>
          </div>
        </template>
        <span>
          {{!page.isEnabled ? 'Available soon...' : ''}}
          {{Array.isArray(page.roles) && !haveUserTheRoles(page.roles) ? `You don\'t have the role for access this page. Necessary roles : ${page.roles.join(',')}` : ''}}
        </span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { usersMixin } from '../mixins/usersMixin'
import baseViewMixin from '@/mixins/baseViewMixin'

export default {
  name: 'Index',
  props: [],
  components: {

  },
  mixins: [usersMixin, baseViewMixin],
  data: function () {
    return {
      isHover: {},
      pages: [
        { name: 'table', path: { name: 'table', params: { dsp: this.$APPNEXUS } }, text: 'Surcouche', icon: 'layers', content: 'Show and edit instructions' },
        { name: 'alertingsMain', path: { name: 'alertingsMain' }, text: 'Alertings', icon: 'notification_important', content: 'Show and manage the alerts' },
        { name: 'surcoucheV2', path: { name: 'surcoucheV2', params: { dsp: this.$YOUTUBE } }, text: 'Surcouche V2', icon: 'notification_important', content: 'Show and manage the surouche v2' },
        { name: 'keystoneV2', path: { name: 'keystoneV2' }, text: 'Keystone V2', icon: 'aspect_ratio', content: 'Keystone Interface' },
        { name: 'cuicuiDemoManage', path: { name: 'cuicuiDemoManage' }, text: 'CuicuiDemoManager', icon: 'donut_small', content: 'Manage customer user interface demo' },
        { name: 'userUi', path: { name: 'userUi' }, text: 'User manager', icon: 'supervised_user_circle', content: 'Manage user of the surcouche', roles: ['api.user.roles:get'] },
        { name: '3pFeed', path: { name: 'thirdPartyFeed' }, text: '3p feed', icon: 'directions', content: 'Third party feed management', roles: ['api.user.roles:get'] }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {
    this.onMounted()
  },
  methods: {
    generateClass (page) {
      return {
        'elevation-1': !this.isHover[page.name] || !page.isEnabled || !this.haveUserTheRoles(page),
        'elevation-4': this.isHover[page.name] && page.isEnabled && this.haveUserTheRoles(page),
        'page-disabled': !page.isEnabled || !this.haveUserTheRoles(page)
      }
    }
  },
  computed: {
    pagesFiltered: function () {
      return this.pages.filter((page) => {
        return this.$pageGuard[page.name] === undefined || this.$pageGuard[page.name].isDisabled[this.$_VUE_ENV] === false
      })
    },
    addEnabledToPages: function () {
      return this.pages.map((page) => {
        let isEnabled = this.$pageGuard[page.name] === undefined || this.$pageGuard[page.name].isDisabled[this.$_VUE_ENV] === false
        return { ...page, isEnabled: isEnabled }
      })
    }
  },
  watch: {

  }
}
</script>

<style>
  .layout-index {
    padding: 5em;
  }

  .index-div {
    cursor: pointer;
    /*height: 100%;*/
    padding: 1em;
  }

  .router-link-index.v-btn {
    text-decoration: none;
    width: 100%;
    height: 100% !important;
    display: block;
    line-height: 12rem;
    margin: 0;
    border-top: 5px solid #1697F6;
  }
  .router-link-index .v-btn__content {
    display: block;
  }

  .index-text-content {
    font-size: 10px;
    padding: 50px;
    white-space: normal;
  }

  .index-link {
    text-align: center;
    line-height: 12rem;
  }

  .index-span-text {
    padding: 19px;
  }

  .flex.index-div.elevation-1.page-disabled {
    cursor: unset !important;
  }

  a.router-link-index.v-btn.v-btn--disabled.v-btn--flat.v-btn--router.theme--light.elevation-1.page-disabled {
    /* box-shadow: unset !important; */
    /* -webkit-box-shadow: unset !important; */
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
    cursor: unset !important;
  }
</style>
